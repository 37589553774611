.navbar {
  z-index: 100;
  overflow: hidden;
  span {
    display: flex;
  }

  @media screen and (max-width: 576px) {
    #responsive-navbar-nav {
      background-color: rgba(0, 0, 0, 0.95);
    }
  }
}
