.wrapper {
  position: absolute;
  z-index: -10;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100%;
  overflow: hidden;
  background: black url('../assets/images/asteroidsCompressed.jpg') no-repeat
    center center/cover;
}
