#scroll-span {
  width: 160px;
}

#scroll-span>h1 {
  position: absolute;
}

#seo {
  visibility: hidden;
}

#marketing {
  visibility: hidden;
}

#presence {
  visibility: hidden;
}

#bliss {
  visibility: hidden;
}

#column {
  padding: 0;
}

@media screen and (max-width: 992px) {
  span>h1 {
    font-size: 1.6rem;
  }

  #logo-color,
  #logo-bw {
    max-height: 200px;
    margin-bottom: 10px;
  }

  #scroll-span {
    width: 100px;
  }

  #pad {
    margin-left: -2%;
  }
}

@media screen and (max-width: 420px) {
  #pad {
    margin-left: -5%;
  }
}