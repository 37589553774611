.logos img {
  max-height: 300px;
}

#logoBW {
  position: absolute;
}

#logoColor,
#logoBW {
  animation-duration: 5s;
  // animation-delay: 1s;

  @media screen and (max-width: 992px) {
    max-height: 150px;
    margin-bottom: 10px;
  }
}